import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import { useRegFormContext } from '../providers/RegisterFromProvider';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { downloadPdfDocument_A } from '../components/pdf/IvadecPDFA';

const Persona10aniosOMas = () => {

    const [loading, setLoading] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Obtener el token CSRF del DOM
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        setCsrfToken(token);
      }, []);

    const { state, dispatch, resetState } = useRegFormContext()
    const handleDownloadPdf = () => {
        const pdfData = {
            ...state,
            grupo_etario: responseData.grupo_etario,
            indice_discapacidad: responseData.indice_discapacidad,
            indice_desempenio: responseData.indice_desempenio,
            grado_discapacidad: responseData.grado_discapacidad,
            calificacion: responseData.calificacion,
        };
        downloadPdfDocument_A(pdfData);
    };

    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState({});
    const { register, handleSubmit, watch, setValue, formState: { isValid, errors }} = useForm({
        mode: 'onChange',
        defaultValues: state.calculo // Inicializar con los valores del contexto
    });
    const watchedFields = watch(); // Observa todos los campos

    const handleNewEvaluation = () => {
        resetState();
        navigate('/');
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
    
        // Construye la fecha sin convertir a UTC
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
    
        // Opciones para el formato de fecha
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('es-CL', options);
    }
    
    const calculateAge = (dateString) => {
        const birthday = new Date(dateString + 'T00:00:00'); // nacimiento en media noche para dar la edad cerrada.
        const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00');
    
        today.setHours(0, 0, 0, 0);
        birthday.setHours(0, 0, 0, 0);
    
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();
    
        if (days < 0) {
            // Ajustar el mes y calcular los días correctos del mes anterior
            months--;
            // Obtener el total de días del último mes (mes anterior al actual)
            let lastMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
            let lastYear = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
            days += new Date(lastYear, lastMonth + 1, 0).getDate(); // Total de días en el mes anterior
        }
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} ${years === 1 ? 'Año' : 'Años'}, ${months} ${months === 1 ? 'mes' : 'meses'}, y ${days} ${days === 1 ? 'día' : 'días'}`;
    }

    function formatOrigenesSecundarios(secundarios, principal) {
        if (!secundarios || secundarios.length === 0) {
            return 'Ninguno';
        }
        const nombresOrigenes = ["Visual", "Auditivo", "Físico", "Mental Intelectual", "Mental Psíquico"];
    
        // Convertimos 'principal' a un índice base 0 para comparar correctamente
        const principalIndex = parseInt(principal) - 1;
    
        const nombresActivos = secundarios.map((origen) => {
            // Convertimos cada origen a índice base 0
            const index = origen - 1;
            // Verificamos que no sea el principal
            return index !== principalIndex ? nombresOrigenes[index] : null;
        }).filter(nombre => nombre !== null); // Filtrar los nulos
    
        //console.log(nombresActivos);
        return nombresActivos.join(', ') || 'Ninguno';
    }
    
    // {getProfesionName(watchedFields.profesion)}
    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }
    // mostrar el sexo e informante según id
    function showSexo(id) {
        const sexo = {
            1: 'Masculino',
            2: 'Femenino',
            3: 'Indeterminado / no binario',
        }
        return sexo[id] || '';
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }

    function showPrincipal(id) {
        const principal = {
            1: 'Visual',
            2: 'Auditivo',
            3: 'Físico',
            4: 'Mental Intelectual',
            5: 'Mental Psíquico',
        }
        return principal[id] || '';
    }

    function showActividad(id) {
        const actividad = {
            1: 'Trabaja',
            2: 'Trabaja y Estudia',
            3: 'Estudia Educación Superior',
            4: 'Estudia en educación especial',
            5: 'Educación escolar regular',
            6: 'No realiza ninguna actividad',
        }
        return actividad[id] || '';
    }

    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [responseData, setResponseData] = useState(null);
    //console.log(responseData)

    // Crear la referencia
    const resultRef = useRef(null);

    // Función para desplazarse al elemento referenciado
    const scrollToResult = () => {
        if (resultRef.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Efecto para realizar el desplazamiento cuando isSubmittedSuccessfully cambia a true
    useEffect(() => {
        if (isSubmittedSuccessfully) {
            scrollToResult();
        }
    }, [isSubmittedSuccessfully]);

    useEffect(() => {
        const updatedErrorMessages = { ...errorMessages };
        for (let key in watchedFields) {
            if (key.endsWith('_A') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            } else if (key.endsWith('_B') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            }
        }
        setErrorMessages(updatedErrorMessages);
    }, [watchedFields.mirar_A, watchedFields.mirar_B, watchedFields.mirar_C,
        watchedFields.escuchar_A, watchedFields.escuchar_B, watchedFields.escuchar_C,
        watchedFields.centrar_atencion_A, watchedFields.centrar_atencion_B, watchedFields.centrar_atencion_C,
        watchedFields.leer_A, watchedFields.leer_B, watchedFields.leer_C,
        watchedFields.resolver_problemas_simples_A, watchedFields.resolver_problemas_simples_B, watchedFields.resolver_problemas_simples_C,
        watchedFields.completar_rutina_diaria_A, watchedFields.completar_rutina_diaria_B, watchedFields.completar_rutina_diaria_C,
        watchedFields.conversar_con_muchas_personas_A, watchedFields.conversar_con_muchas_personas_B, watchedFields.conversar_con_muchas_personas_C,
        watchedFields.discusion_con_muchas_personas_A, watchedFields.discusion_con_muchas_personas_B, watchedFields.discusion_con_muchas_personas_C,
        watchedFields.utilizacion_dispositivos_telecomunicacion_A, watchedFields.utilizacion_dispositivos_telecomunicacion_B, watchedFields.utilizacion_dispositivos_telecomunicacion_C,
        watchedFields.ponerse_de_pie_A, watchedFields.ponerse_de_pie_B, watchedFields.ponerse_de_pie_C,
        watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_A, watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_B, watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_C,
        watchedFields.utilizacion_de_transporte_publico_con_motor_A, watchedFields.utilizacion_de_transporte_publico_con_motor_B, watchedFields.utilizacion_de_transporte_publico_con_motor_C,
        watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_A, watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_B, watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_C,
        watchedFields.ponerse_la_ropa_A, watchedFields.ponerse_la_ropa_B, watchedFields.ponerse_la_ropa_C,
        watchedFields.comer_A, watchedFields.comer_B, watchedFields.comer_C,
        watchedFields.beber_A, watchedFields.beber_B, watchedFields.beber_C,
        watchedFields.asegurar_el_propio_bienestar_fisico_A, watchedFields.asegurar_el_propio_bienestar_fisico_B, watchedFields.asegurar_el_propio_bienestar_fisico_C,
        watchedFields.mantenimiento_de_la_salud_A, watchedFields.mantenimiento_de_la_salud_B, watchedFields.mantenimiento_de_la_salud_C,
        watchedFields.comprar_A, watchedFields.comprar_B, watchedFields.comprar_C,
        watchedFields.preparar_comidas_sencillas_A, watchedFields.preparar_comidas_sencillas_B, watchedFields.preparar_comidas_sencillas_C,
        watchedFields.limpieza_de_la_vivienda_A, watchedFields.limpieza_de_la_vivienda_B, watchedFields.limpieza_de_la_vivienda_C,
        watchedFields.interacciones_interpersonales_basicas_A, watchedFields.interacciones_interpersonales_basicas_B, watchedFields.interacciones_interpersonales_basicas_C,
        watchedFields.establecer_relaciones_A, watchedFields.establecer_relaciones_B, watchedFields.establecer_relaciones_C,
        watchedFields.relaciones_informales_con_amigos_A, watchedFields.relaciones_informales_con_amigos_B, watchedFields.relaciones_informales_con_amigos_C,
        watchedFields.relaciones_sentimentales_A, watchedFields.relaciones_sentimentales_B, watchedFields.relaciones_sentimentales_C,
        watchedFields.relaciones_familiares_A, watchedFields.relaciones_familiares_B, watchedFields.relaciones_familiares_C,
        watchedFields.trabajo_remunerado_A, watchedFields.trabajo_remunerado_B, watchedFields.trabajo_remunerado_C,
        watchedFields.formacion_profesional_A, watchedFields.formacion_profesional_B, watchedFields.formacion_profesional_C,
        watchedFields.educacion_escolar_A, watchedFields.educacion_escolar_B, watchedFields.educacion_escolar_C,
        watchedFields.socializacion_A, watchedFields.socializacion_B, watchedFields.socializacion_C,
    ]);

    const edad = parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]);
    let actividad = parseInt(state.actividad.actividad);

    // Códigos excluyentes
    useEffect(() => {
        
        if (parseInt(edad) > 20) {
            setValue("relaciones_informales_con_amigos_A", "0");
            setValue("relaciones_informales_con_amigos_B", "0");
            setValue("relaciones_informales_con_amigos_C", "0");
        } else {
            setValue("relaciones_informales_con_amigos_A", "-");
            setValue("relaciones_informales_con_amigos_B", "-");
            setValue("relaciones_informales_con_amigos_C", "-");
        }
        if ((parseInt(edad) >= 10 && parseInt(edad) < 20)) {
            setValue("relaciones_sentimentales_A", "0");
            setValue("relaciones_sentimentales_B", "0");
            setValue("relaciones_sentimentales_C", "0");
        } else {
            setValue("relaciones_sentimentales_A", "-");
            setValue("relaciones_sentimentales_B", "-");
            setValue("relaciones_sentimentales_C", "-");
        }

        // Si no realiza ninguna activdad y es mayor a 17 años 11 meses y 29 días
        if ( edad > 17 && actividad === 6 ) {
            actividad = 1
        }

        if (edad > 9 && edad < 18 && actividad === 6) {
            actividad = 4
        }
        
        // Trabaja D850
        if (edad > 9 && (actividad === 1 || actividad === 2)) {
            setValue("formacion_profesional_A", "0");
            setValue("formacion_profesional_B", "0");
            setValue("formacion_profesional_C", "0");
            setValue("educacion_escolar_A", "0");
            setValue("educacion_escolar_B", "0");
            setValue("educacion_escolar_C", "0");
            setValue("trabajo_remunerado_A", "-");
            setValue("trabajo_remunerado_B", "-");
            setValue("trabajo_remunerado_C", "-");
        } 
        if (edad > 9 && actividad === 3) {
            console.log('estasmos aqui', (edad > 9) && (actividad === 3))
            setValue("trabajo_remunerado_A", "0");
            setValue("trabajo_remunerado_B", "0");
            setValue("trabajo_remunerado_C", "0");
            setValue("educacion_escolar_A", "0");
            setValue("educacion_escolar_B", "0");
            setValue("educacion_escolar_C", "0");
            setValue("formacion_profesional_A", "-");
            setValue("formacion_profesional_B", "-");
            setValue("formacion_profesional_C", "-");
        }
        if (edad > 9 && (actividad === 4 || actividad === 5) ) {
            setValue("trabajo_remunerado_A", "0");
            setValue("trabajo_remunerado_B", "0");
            setValue("trabajo_remunerado_C", "0");
            setValue("formacion_profesional_A", "0");
            setValue("formacion_profesional_B", "0");
            setValue("formacion_profesional_C", "0");
            setValue("educacion_escolar_A", "-");
            setValue("educacion_escolar_B", "-");
            setValue("educacion_escolar_C", "-");
        } else {
        }
        
        
        

    }, [state.personal.fecha_nacimiento_usuario, state.actividad.actividad, setValue]);
    
    const onSubmit = async (values) => {
        const updatedValues = { ...values };

        // Iterar sobre las claves para actualizar los valores que terminen en '_C'
        Object.keys(updatedValues).forEach(key => {
            if (key.endsWith('_C')) {
                updatedValues[key] = updatedValues[key] === '-' ? '0' : updatedValues[key];
            }
        });

        let newErrorMessages = {};

        for (let key in updatedValues) {
            if (key.endsWith('_A') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo A es obligatorio';
            } else if (key.endsWith('_B') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo B es obligatorio';
            }
        }

        if (Object.keys(newErrorMessages).length > 0) {
            setErrorMessages(newErrorMessages);
            return;
        }
        // Asegúrate de incluir todos los datos que necesitas enviar
        if (isValid) {
            dispatch({ type: 'SET_DATA_CALCULO', data: updatedValues });
            // Otras acciones que quieras realizar después de la actualización del estado
        }
        const dataToSend = {
            evaluador: {
                rut: state.evaluador.rut,
                nombre: state.evaluador.nombre,
                apellido_paterno: state.evaluador.apellido_paterno,
                apellido_materno: state.evaluador.apellido_materno,
                profesion: state.evaluador.profesion
            },
            usuario: {
                rut_usuario: state.personal.rut_usuario,
                nombre_usuario: state.personal.nombre_usuario,
                apellido_paterno_usuario: state.personal.apellido_paterno_usuario,
                apellido_materno_usuario: state.personal.apellido_materno_usuario,
                fecha_nacimiento_usuario: state.personal.fecha_nacimiento_usuario,
                sexo_usuario: state.personal.sexo_usuario,
                informante: state.personal.informante,
                rut_acompanante: state.personal?.rut_acompanante,
                nombre_acompanante: state.personal?.nombre_acompanante,
                apellido_paterno_acompanante: state.personal?.apellido_paterno_acompanante,
                apellido_materno_acompanante: state.personal?.apellido_materno_acompanante
            },
            origen: {
                principal: state.origen.principal,
                secundario: state.origen.secundario
            },
            gradoDificultad: {
                ...updatedValues,
            }
        };
        setLoading(true);
        //log(dataToSend);
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/ivadec_A_add/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify(dataToSend)
            });
    
            if (!response.ok) {
                // Si la respuesta no es 'ok', intenta obtener más detalles del error
                const errorData = await response.json(); // asumiendo que el servidor devuelve un JSON con detalles del error
                const errorMsg = `Error en el envío de datos: ${response.status} - ${response.statusText}. Detalles: ${JSON.stringify(errorData)}`;
                throw new Error(errorMsg);
            }
        
    
            // Procesa la respuesta del servidor aquí
            const data = await response.json();  // Asegúrate de que el backend envía una respuesta JSON
            setResponseData(data);  // Almacena la respuesta en el estado
            setIsSubmittedSuccessfully(true);
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGoBack = () => {
        handleSubmit((values) => {
            dispatch({ type: 'SET_DATA_CALCULO', data: values });  // Asumiendo que este es el tipo de acción para guardar los datos de cálculo
            navigate(-1);  // Navegar hacia atrás después de actualizar el estado
        })();  // Llama a la función handleSubmit programáticamente
    };

    return (
        <>
            {/* <div className="grid min-h-full grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1"> */}
            <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
                <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
                    <div className='p-2 rounded-[5px] bg-color-secondary-azul'>
                        <p className='font-bold text-lg font-Roboto dark:text-white text-white'>PERSONA DE 10 AÑOS O MÁS</p>
                    </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    >
                    
                    <div className="overflow-x-auto relative">
                        <table className="w-full text-sm divide-y divide-gray-200">
                        {/* Encabezado de la tabla */}
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">Código</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">Actividades</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">A</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">B</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">C</th>
                            </tr>
                        </thead>
                        {/* Cuerpo de la tabla */}
                        <tbody className="divide-y divide-gray-200">
                            {/* Itera por cada fila de datos aquí */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d110</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mirar</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select 
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1 font-Roboto"
                                        {...register("mirar_A", { required: true })}    
                                    >
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.mirar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select 
                                        {...register("mirar_B")}  
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.mirar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.mirar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mirar_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d115</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Escuchar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.escuchar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_A}</span>}
                                        </div>   
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.escuchar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.escuchar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d160</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Centrar la atención</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.centrar_atencion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.centrar_atencion_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.centrar_atencion_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.centrar_atencion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.centrar_atencion_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d166</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Leer</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("leer_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.leer_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.leer_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("leer_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.leer_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.leer_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.leer_B}</span>}
                                        </div> 
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("leer_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            {/* ################# SEGUNDA SECCIÓN DEL CUESTIONARIO #################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1750</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Resolver problemas simples</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.resolver_problemas_simples_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.resolver_problemas_simples_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.resolver_problemas_simples_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.resolver_problemas_simples_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.resolver_problemas_simples_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d2302</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Completar la rutina diaria</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("completar_rutina_diaria_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.completar_rutina_diaria_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.completar_rutina_diaria_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("completar_rutina_diaria_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.completar_rutina_diaria_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.completar_rutina_diaria_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.completar_rutina_diaria_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("completar_rutina_diaria_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d160</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Conversar con muchas personas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("conversar_con_muchas_personas_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.conversar_con_muchas_personas_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.conversar_con_muchas_personas_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("conversar_con_muchas_personas_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.conversar_con_muchas_personas_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.conversar_con_muchas_personas_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.conversar_con_muchas_personas_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("conversar_con_muchas_personas_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d3551</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Discusión con muchas personas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("discusion_con_muchas_personas_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.discusion_con_muchas_personas_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.discusion_con_muchas_personas_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("discusion_con_muchas_personas_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.discusion_con_muchas_personas_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.discusion_con_muchas_personas_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.discusion_con_muchas_personas_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("discusion_con_muchas_personas_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            {/* ######################## TERCERA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d3600</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Utilización de dispositivos de telecomunicación</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("utilizacion_dispositivos_telecomunicacion_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.utilizacion_dispositivos_telecomunicacion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_dispositivos_telecomunicacion_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("utilizacion_dispositivos_telecomunicacion_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.utilizacion_dispositivos_telecomunicacion_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.utilizacion_dispositivos_telecomunicacion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_dispositivos_telecomunicacion_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                    {...register("utilizacion_dispositivos_telecomunicacion_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4104</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Ponerse de pie</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_de_pie_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.ponerse_de_pie_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_de_pie_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_de_pie_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.ponerse_de_pie_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.ponerse_de_pie_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_de_pie_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_de_pie_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4602</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Desplazarse fuera del hogar y otros edificios</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_fuera_del_hogar_y_otros_edificios_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_fuera_del_hogar_y_otros_edificios_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_fuera_del_hogar_y_otros_edificios_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4702</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Utilización de transporte público con motor</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("utilizacion_de_transporte_publico_con_motor_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.utilizacion_de_transporte_publico_con_motor_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_de_transporte_publico_con_motor_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("utilizacion_de_transporte_publico_con_motor_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.utilizacion_de_transporte_publico_con_motor_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.utilizacion_de_transporte_publico_con_motor_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_de_transporte_publico_con_motor_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                    {...register("utilizacion_de_transporte_publico_con_motor_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            {/* ######################## CUARTA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d530</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Higiene personal relacionada con los procesos de excreción</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("higiene_personal_relacionada_con_los_procesos_de_excrecion_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("higiene_personal_relacionada_con_los_procesos_de_excrecion_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                    {...register("higiene_personal_relacionada_con_los_procesos_de_excrecion_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d5400</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Ponerse la ropa</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_la_ropa_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.ponerse_la_ropa_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_la_ropa_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_la_ropa_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.ponerse_la_ropa_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.ponerse_la_ropa_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_la_ropa_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_la_ropa_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d550</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comer</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.comer_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comer_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.comer_B ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.comer_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comer_A}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d560</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Beber</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.beber_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.beber_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.beber_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.beber_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.beber_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            {/* ######################## QUINTA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d5700</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Asegurar el propio bienestar físico</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("asegurar_el_propio_bienestar_fisico_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.asegurar_el_propio_bienestar_fisico_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.asegurar_el_propio_bienestar_fisico_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("asegurar_el_propio_bienestar_fisico_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.asegurar_el_propio_bienestar_fisico_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.asegurar_el_propio_bienestar_fisico_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.asegurar_el_propio_bienestar_fisico_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("asegurar_el_propio_bienestar_fisico_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d5702</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mantenimiento de la salud</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantenimiento_de_la_salud_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.mantenimiento_de_la_salud_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mantenimiento_de_la_salud_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantenimiento_de_la_salud_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.mantenimiento_de_la_salud_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.mantenimiento_de_la_salud_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mantenimiento_de_la_salud_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mantenimiento_de_la_salud_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d6200</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comprar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comprar_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.comprar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comprar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comprar_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.comprar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.comprar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comprar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comprar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d6300</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Preparar comidas sencillas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("preparar_comidas_sencillas_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.preparar_comidas_sencillas_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.preparar_comidas_sencillas_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("preparar_comidas_sencillas_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.preparar_comidas_sencillas_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.preparar_comidas_sencillas_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.preparar_comidas_sencillas_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("preparar_comidas_sencillas_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            {/* ######################## SEXTO SECCIÓN ############################### */}
                            <tr className=''>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d6402</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Limpieza de la vivienda</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("limpieza_de_la_vivienda_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.limpieza_de_la_vivienda_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.limpieza_de_la_vivienda_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("limpieza_de_la_vivienda_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.limpieza_de_la_vivienda_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.limpieza_de_la_vivienda_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.limpieza_de_la_vivienda_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("limpieza_de_la_vivienda_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d710</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Interacciones interpersonales básicas</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.interacciones_interpersonales_basicas_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.interacciones_interpersonales_basicas_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.interacciones_interpersonales_basicas_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.interacciones_interpersonales_basicas_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.interacciones_interpersonales_basicas_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d7200</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Establecer relaciones</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("establecer_relaciones_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.establecer_relaciones_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.establecer_relaciones_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("establecer_relaciones_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.establecer_relaciones_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.establecer_relaciones_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.establecer_relaciones_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("establecer_relaciones_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d7500</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Relaciones informales con amigos</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_informales_con_amigos_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]) > 20} >
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.relaciones_informales_con_amigos_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_informales_con_amigos_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_informales_con_amigos_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]) > 20} >
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.relaciones_informales_con_amigos_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.relaciones_informales_con_amigos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_informales_con_amigos_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_informales_con_amigos_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]) > 20} >
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            {/* ######################## SEPTIMA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d7700</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Relaciones sentimentales</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_sentimentales_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]) < 20} > 
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.relaciones_sentimentales_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_sentimentales_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_sentimentales_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]) < 20} > 
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.relaciones_sentimentales_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.relaciones_sentimentales_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_sentimentales_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_sentimentales_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]) < 20} > 
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d760</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Relaciones familiares</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_familiares_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.relaciones_familiares_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_familiares_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_familiares_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.relaciones_familiares_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.relaciones_familiares_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_familiares_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_familiares_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d850</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Trabajo remunerado</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("trabajo_remunerado_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        //( ((parseInt(edad) >= 10 && parseInt(edad) < 18) || ( parseInt(actividad) === (4))) || ( (parseInt(edad) >= 18) && ( parseInt(actividad) === (3))) )
                                        disabled={
                                            (() => {
                                                return (
                                                    (edad > 9 && actividad === 3) || (edad > 9 && (actividad === 4 || actividad === 5)) || (edad > 9 && edad < 18 && actividad === 6)
                                                );
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.trabajo_remunerado_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.trabajo_remunerado_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("trabajo_remunerado_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                return (
                                                    (edad > 9 && actividad === 3) || (edad > 9 && (actividad === 4 || actividad === 5)) || (edad > 9 && edad < 18 && actividad === 6)
                                                );
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.trabajo_remunerado_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.trabajo_remunerado_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.trabajo_remunerado_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("trabajo_remunerado_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                return (
                                                    (edad > 9 && actividad === 3) || (edad > 9 && (actividad === 4 || actividad === 5)) || (edad > 9 && edad < 18 && actividad === 6)
                                                );
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d825</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Formación profesional</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("formacion_profesional_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                console.log(actividad, 'actividad en d825');
                                                return ( 
                                                    (edad > 9 && (actividad === 1 || actividad === 2)) || (edad > 9 && (actividad === 4 || actividad === 5)) || (edad > 9 && edad < 18 && actividad === 6) || ( edad > 17 && actividad === 6 )
                                                )
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.formacion_profesional_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.formacion_profesional_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("formacion_profesional_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                return (
                                                    (edad > 9 && (actividad === 1 || actividad === 2)) || (edad > 9 && (actividad === 4 || actividad === 5)) || (edad > 9 && edad < 18 && actividad === 6) || ( edad > 17 && actividad === 6 )
                                                )
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.formacion_profesional_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.formacion_profesional_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.formacion_profesional_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("formacion_profesional_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                return (
                                                    (edad > 9 && (actividad === 1 || actividad === 2)) || (edad > 9 && (actividad === 4 || actividad === 5)) || (edad > 9 && edad < 18 && actividad === 6) || ( edad > 17 && actividad === 6 )
                                                )
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d820</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Educación escolar</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("educacion_escolar_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                //const edad = parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]);
                                                const actividad = parseInt(state.actividad.actividad);
                                                return (
                                                    (edad > 9 && (actividad === 1 || actividad === 2)) || (edad > 9 && actividad === 3) || ( edad > 17 && actividad === 6 )
                                                );
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.educacion_escolar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.educacion_escolar_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("educacion_escolar_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                //const edad = parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]);
                                                const actividad = parseInt(state.actividad.actividad);
                                                return (
                                                    (edad > 9 && (actividad === 1 || actividad === 2)) || (edad > 9 && actividad === 3) || ( edad > 17 && actividad === 6 )
                                                );
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.educacion_escolar_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.educacion_escolar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.educacion_escolar_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("educacion_escolar_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1"
                                        disabled={
                                            (() => {
                                                //const edad = parseInt(calculateAge(state.personal.fecha_nacimiento_usuario).split(' ')[0]);
                                                const actividad = parseInt(state.actividad.actividad);
                                                return (
                                                    (edad > 9 && (actividad === 1 || actividad === 2)) || (edad > 9 && actividad === 3) || ( edad > 17 && actividad === 6 )
                                                );
                                            })()
                                        }>
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d9205</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Socialización</td>
                                {/* Columna A */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("socializacion_A")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className='absolute left-0'>
                                            {errorMessages.socializacion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.socializacion_A}</span>}
                                        </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("socializacion_B")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                        <div className={`absolute ${errorMessages.socializacion_A ? 'left-44' : 'left-0' }`}>
                                            {errorMessages.socializacion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.socializacion_B}</span>}
                                        </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("socializacion_C")}
                                        className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-indigo-200 focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                                
                            </tr>
                            
                            
                        </tbody>
                        </table>
                    </div>
                    {/* Botones al final */}
                    <div className='flex justify-end gap-3 mt-[11px]'>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleGoBack}
                            >
                                Volver
                            </button>
                        </div>
                        <div className='w-[254px] h-[48px]'>
                            <button
                                type='submit'
                                // disabled={isSubmittedSuccessfully}
                                className='bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                            >
                                {loading ? <ScaleLoader color='#ffffff' loading={true} height={30} width={4} radius={2} margin={2} /> : 'Calcular'}
                            </button>
                        </div>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleNewEvaluation}
                            >
                                Nueva Evaluación
                            </button>
                        </div>
                    </div>
                </form>

                </div>




                {/* AQUI VIENEN LOS DATOS ANTERIORES */}
                {/* DATOS DEL EVALUADOR */}
                <div className="overflow-auto lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF] rounded-e-[5px]">
                        <div className='text-left flex flex-col gap-1'>
                        <h2 className="text-[18px] font-bold leading-7 text-gray-900 font-Roboto text-center">Datos Ingresados</h2>

                            <div className='py-3 border-t border-b border-gray-300 mt-2'>
                                <h3 className='font-Roboto text-sm py-1 font-semibold'>Evaluador</h3>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Rut</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.evaluador && state.evaluador.rut}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Nombre</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.evaluador && state.evaluador.nombre}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Apellido Paterno</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.evaluador && state.evaluador.apellido_paterno}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Apellido Materno</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.evaluador && state.evaluador.apellido_materno}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Profesión</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.evaluador && getProfesionName(state.evaluador?.profesion)}</p>
                                </div>
                            </div>
                        </div>
                        {/* DATOS DEL USUARIO */}
                        <div className='text-left flex flex-col gap-1'>
                            <div className='pb-4 border-b border-gray-300'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Usuario</h2>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Rut</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.rut_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Nombre</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.nombre_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Apellido Paterno</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.apellido_paterno_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Apellido Materno</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.apellido_materno_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Fecha de Nacimiento</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.fecha_nacimiento_usuario ? formatDate(state.personal.fecha_nacimiento_usuario
                                        ) : ''}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Edad</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.fecha_nacimiento_usuario ? calculateAge(state.personal.fecha_nacimiento_usuario) : ''} </p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Sexo</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && showSexo(state.personal?.sexo_usuario)}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 w-1/2 font-semibold">Informante</p>
                                    <p className="text-sm text-gray-500 font-semibold">: {state.personal && showInformante(state.personal?.informante)}</p>
                                </div>
                                {state.personal && showInformante(state.personal?.informante) === 'Acompañante' ? (
                                    <>
                                        <div className='flex flex-col mt-1'>
                                            <h3 className='font-Roboto text-sm py-1 font-semibold'>Acompañante</h3>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 w-1/2 font-semibold">Rut</p>
                                                <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.rut_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 w-1/2 font-semibold">Nombre</p>
                                                <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.nombre_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 w-1/2 font-semibold">Apellido Paterno</p>
                                                <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.apellido_paterno_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 w-1/2 font-semibold">Apellido Materno</p>
                                                <p className="text-sm text-gray-500 font-semibold">: {state.personal && state.personal.apellido_materno_acompanante}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div className='text-left flex flex-col gap-1'>
                                <div className='pb-4 border-b border-gray-300'>
                                    <h2 className="font-Roboto text-sm py-1 font-semibold">Origen</h2>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Principal</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.origen && showPrincipal(state.origen.principal)}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Secundario(s)</p>
                                        <div className='w-1/2'>
                                            <p className="text-sm text-gray-500 font-Roboto">: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                                        </div>
                                    </div>
                                </div>    
                            </div>

                            <div className='text-left flex flex-col gap-1'>
                                <div className='pb-4 border-b border-gray-300'>
                                    <h2 className="font-Roboto text-sm py-1 font-semibold">Actividad</h2>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Actividad</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.actividad && showActividad(state.actividad.actividad)}</p>
                                    </div>
                                </div>    
                            </div>
                            
                        </div>

                        {/* Calculadora */}
                        <div className='text-left border-b flex flex-col gap-1'>
                            <div className='pb-4'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Cálculo</h2>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Mirar</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.mirar_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.mirar_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.mirar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Escuchar</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.escuchar_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.escuchar_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.escuchar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Centrar la atención</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.centrar_atencion_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.centrar_atencion_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.centrar_atencion_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Leer</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.leer_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.leer_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.leer_C}</p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Resolver Problemas</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.resolver_problemas_simples_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.resolver_problemas_simples_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.resolver_problemas_simples_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Completar Rutina</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.completar_rutina_diaria_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.completar_rutina_diaria_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.completar_rutina_diaria_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Conversar Personas</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.conversar_con_muchas_personas_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.conversar_con_muchas_personas_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.conversar_con_muchas_personas_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Discusión Personas</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.discusion_con_muchas_personas_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.discusion_con_muchas_personas_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.discusion_con_muchas_personas_C}</p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Telecomunicaciones</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.utilizacion_dispositivos_telecomunicacion_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.utilizacion_dispositivos_telecomunicacion_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.utilizacion_dispositivos_telecomunicacion_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Ponerse de pie</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.ponerse_de_pie_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.ponerse_de_pie_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.ponerse_de_pie_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Desplazaerse fuera</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Utlilizar transporte</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.utilizacion_de_transporte_publico_con_motor_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.utilizacion_de_transporte_publico_con_motor_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.utilizacion_de_transporte_publico_con_motor_C}</p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Higiene personal</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Ponerse la ropa</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.ponerse_la_ropa_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.ponerse_la_ropa_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.ponerse_la_ropa_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Comer</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.comer_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.comer_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.comer_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Beber</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.beber_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.beber_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.beber_C}</p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Bienestar Físico</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.asegurar_el_propio_bienestar_fisico_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.asegurar_el_propio_bienestar_fisico_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.asegurar_el_propio_bienestar_fisico_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Mantenimiento salud</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.mantenimiento_de_la_salud_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.mantenimiento_de_la_salud_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.mantenimiento_de_la_salud_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Comprar</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.comprar_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.comprar_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.comprar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Preparar comidas</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.preparar_comidas_sencillas_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.preparar_comidas_sencillas_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.preparar_comidas_sencillas_C}</p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Limpieza vivienda</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.limpieza_de_la_vivienda_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.limpieza_de_la_vivienda_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.limpieza_de_la_vivienda_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Interacciones básicas</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.interacciones_interpersonales_basicas_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.interacciones_interpersonales_basicas_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.interacciones_interpersonales_basicas_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Establecer relaciones</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.establecer_relaciones_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.establecer_relaciones_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.establecer_relaciones_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Relaciones amigos</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.relaciones_informales_con_amigos_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.relaciones_informales_con_amigos_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.relaciones_informales_con_amigos_C}</p>
                                </div>
                            </div>
                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Relaciones sentimentales</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.relaciones_sentimentales_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.relaciones_sentimentales_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.relaciones_sentimentales_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Relaciones familiares</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.relaciones_familiares_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.relaciones_familiares_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.relaciones_familiares_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Trabajo remunerado</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.trabajo_remunerado_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.trabajo_remunerado_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.trabajo_remunerado_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Formación profesional</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.formacion_profesional_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.formacion_profesional_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.formacion_profesional_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Educación escolar</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.educacion_escolar_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.educacion_escolar_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.educacion_escolar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 w-9/12 font-Roboto'>Socialización</p> 
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>A: {watchedFields.socializacion_A}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>B: {watchedFields.socializacion_B}</p>
                                    <p className='text-sm text-gray-500 w-2/12 font-Roboto'>C: {watchedFields.socializacion_C}</p>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>
            {
                isSubmittedSuccessfully ? (
                    <>
                        <div className='border w-full mt-12 rounded-[5px] bg-white shadow hover:shadow-2xl dark:bg-color-azul-contraste' ref={resultRef}>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>Datos guardados con exito</h2>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>RESULTADOS:</h2>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Origen principal de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {state.origen && showPrincipal(state.origen.principal)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Otros orígenes</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grupo etario</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grupo_etario : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_discapacidad : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de desempeño</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_desempenio : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Porcentaje de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grado_discapacidad : 'Cargando...'} %</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grado de discapacidad:</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.calificacion : 'Cargando...'}</p>
                            </div>

                            <div className='flex justify-center items-center my-8'>
                                <button onClick={() => handleDownloadPdf(state)} className="px-16 py-2 bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-Roboto rounded-[5px]">Guardar PDF</button>
                            </div>

                        </div>
                    </>
                ) : null
            }
        </>
    )
}

export default Persona10aniosOMas