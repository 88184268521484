import { useNavigate, useOutletContext } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegFormContext } from '../providers/RegisterFromProvider';
import { validateRut, formatRut } from 'rutlib';
import Limpiar from './helpers/Limpiar';

const DatosUsuario = () => {
    const { state, dispatch } = useRegFormContext()
    const { textSize } = useOutletContext();
    
    const { register, handleSubmit, watch, formState: { isValid, errors } } = useForm({ 
        mode: 'onChange', 
        defaultValues: state.personal 
    });
    const watchedFields = watch(); // Observa todos los campos

    const navigate = useNavigate('informante');

    const formatDate = (dateString) => {
        if (!dateString) return '';
    
        // Construye la fecha sin convertir a UTC
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
    
        // Opciones para el formato de fecha
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('es-CL', options);
    }
    
    const calculateAge = (dateString) => {
        const birthday = new Date(dateString + 'T00:00:00'); // nacimiento en media noche para dar la edad cerrada.
        const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00');
    
        today.setHours(0, 0, 0, 0);
        birthday.setHours(0, 0, 0, 0);
    
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();
    
        if (days < 0) {
            // Ajustar el mes y calcular los días correctos del mes anterior
            months--;
            // Obtener el total de días del último mes (mes anterior al actual)
            let lastMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
            let lastYear = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
            days += new Date(lastYear, lastMonth + 1, 0).getDate(); // Total de días en el mes anterior
        }
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} ${years === 1 ? 'Año' : 'Años'}, ${months} ${months === 1 ? 'mes' : 'meses'}, y ${days} ${days === 1 ? 'día' : 'días'}`;
    }
    

    // postrar la profesion según su id 
    // {getProfesionName(watchedFields.profesion)}
    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }

    // mostrar el sexo e informante según id
    function showSexo(id) {
        const sexo = {
            1: 'Masculino',
            2: 'Femenino',
            3: 'Indeterminado / no binario',
        }
        return sexo[id] || '';
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }

    // Validar fecha mayor a 6 meses:
    const esMayor6meses = (dateString) => {
        const nacimiento = new Date(dateString);
        const hoy = new Date();
        const mayor6meses = new Date(hoy.setMonth(hoy.getMonth() - 6));
    
        return nacimiento <= mayor6meses; // Retorna true si la fecha es mayor a 6 meses
    };

    const onSubmit = (values) => {
        //Verificar si el informante es el acompañante y establecer los campos del acompañante como null si están vacíos
        if (values.informante !== '2') {
            values.rut_acompanante = '';
            values.nombre_acompanante = '';
            values.apellido_paterno_acompanante = '';
            values.apellido_materno_acompanante = '';
        }
        //console.log('enviar datos', values);
        if (isValid) {
            dispatch({ type: 'SET_DATA_PERSONAL', data: values })
        }
        navigate('/paso3_datosUsuarioOrigenDiscapacida');
    }

    return (
        <>
            <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
                <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <div className='w-full'>
                            <h2 className={`${textSize === 'small' ? 'text-[21px]' : textSize === 'medium' ? 'text-[23px]' : 'text-[25px]'} font-semibold leading-7 font-Roboto dark:text-white`}>Ingrese datos del usuario</h2>
                        <div className="mt-[15px] space-y-8 sm:space-y-0 sm:border-t sm:border-b sm:pb-10">
        

                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                                <label htmlFor="rut_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                    Rut
                                </label>
                                <div className="mt-2 sm:col-span-3 sm:mt-0">
                                    <input
                                    id="rut_usuario"
                                    name="rut_usuario"
                                    type="text"
                                    autoComplete="off"
                                    {...register('rut_usuario',{
                                        required: 'Este campo es requerido',
                                        maxLength: {
                                            value: 12,
                                            message: 'El RUT no debe tener más de 12 caracteres'  // Mensaje de error si el input excede los 12 caracteres
                                        },
                                        validate: {
                                            validRut: value => validateRut(formatRut(value)) || 'Rut inválido',
                                        }
                                    })}
                                    placeholder='Ingrese el rut del usuario'
                                    className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                    />
                                    <div className='text-left'>
                                        {errors.rut_usuario && <span className='text-red-500 font-semibold font-Roboto text-[13px]'>{errors.rut_usuario.message}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                            <label htmlFor="nombre_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                Nombre
                            </label>
                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                <input
                                id="nombre_usuario"
                                name="nombre_usuario"
                                type="text"
                                autoComplete="off"
                                {...register('nombre_usuario', {
                                    required: 'Nombre es requerido',
                                    minLength: {
                                        value: 3,
                                        message: 'Nombre debe tener al menos 3 letras'
                                    }
                                })}
                                placeholder='Ingrese el o los nombres del usuario'
                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                            <label htmlFor="apellido_paterno_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                Apellido Paterno
                            </label>
                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                <input
                                id="apellido_paterno_usuario"
                                name="apellido_paterno_usuario"
                                required
                                type="text"
                                autoComplete="off"
                                {...register('apellido_paterno_usuario')}
                                placeholder='Ingrese el apellido paterno del usuario'
                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                            <label htmlFor="apellido_materno_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                Apellido Materno
                            </label>
                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                <input
                                id="apellido_materno_usuario"
                                name="apellido_materno_usuario"
                                type="text"
                                {...register('apellido_materno_usuario')}
                                autoComplete="off"
                                placeholder='Ingrese el apellido materno del usuario'
                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                />
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                            <label htmlFor="fecha_nacimiento_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                Fecha de Nacimiento
                            </label>
                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                <input
                                id="fecha_nacimiento_usuario"
                                name="fecha_nacimiento_usuario"
                                type="date"
                                autoComplete="off"
                                {...register('fecha_nacimiento_usuario', {
                                    required: 'La fecha de nacimiento es requerida',
                                    validate: {
                                        esMayor6meses: value => esMayor6meses(value) || 'La edad del usuario debe ser mayor a 6 meses'
                                    }
                                })}
                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                />
                                <div className='text-left'>
                                    {errors.fecha_nacimiento_usuario && <p className='text-red-500 font-semibold font-Roboto text-[13px]'>{errors.fecha_nacimiento_usuario.message}</p>}
                                </div>
                            </div>
                            </div>


                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                            <label htmlFor="sexo_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                Sexo
                            </label>
                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                <select
                                id="sexo_usuario"
                                name="sexo_usuario"
                                {...register('sexo_usuario', {validate: value => value !== "0"}) } // Validación personalizada})}
                                autoComplete="off"
                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                >
                                <option value="0" className='text-gray-300'>Seleccione</option>
                                <option value="1">Masculino</option>
                                <option value="2">Femenino</option>
                                <option value="3">Indeterminado / no binario</option>
                                </select>
                            </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2 sm:mt-[25px]">
                            <label htmlFor="informante_usuario" className={`block ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} text-left font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                Informante
                            </label>
                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                <select
                                id="informante"
                                name="informante"
                                autoComplete="informante"
                                {...register('informante', {validate: value => value !== "0"})}
                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                >
                                <option value="0" className='text-gray-300'>Seleccione</option>
                                <option value="1">Usuario</option>
                                <option value="2">Acompañante</option>
                                </select>
                            </div>
                            </div>

                            {
                                watchedFields.informante === '2' && (
                                    <>
                                        <div className='py-2 rounded-xl'>
                                            
                                        <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                            <label htmlFor="rut_acompanante" className={`block text-left ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                                Rut Acompañante
                                            </label>
                                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                                <input
                                                type="text"
                                                name="rut_acompanante"
                                                id="rut_acompanante"
                                                placeholder='Ingrese el rut del acompañante'
                                                {...register('rut_acompanante', {
                                                    required: 'Este campo es requerido',  // Campo requerido condicionalmente si informante es "2"
                                                    maxLength: {
                                                        value: 12,
                                                        message: 'El RUT no debe tener más de 12 caracteres'  // Mensaje si el input excede 12 caracteres
                                                    },
                                                    validate: {
                                                        validRut: value => validateRut(formatRut(value)) || 'Rut inválido',  // Validación personalizada del RUT
                                                    }
                                                })}
                                                autoComplete="off"
                                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                                />
                                                <div className='text-left'>
                                                    {errors.rut_acompanante && <span className='text-red-500 font-semibold font-Roboto text-[13px]'>{errors.rut_acompanante.message}</span>}
                                                </div>
                                            </div>
                                            </div>

                                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                            <label htmlFor="nombre_acompanante" className={`block text-left ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                                Nombre
                                            </label>
                                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                                <input
                                                type="text"
                                                name="nombre_acompanante"
                                                id="nombre_acompanante"
                                                placeholder='Ingrese el nombre del acompañante'
                                                autoComplete="off"
                                                {...register("nombre_acompanante", {
                                                    required: 'Este campo es requerido',  // Campo requerido condicionalmente si informante es "2
                                                })}
                                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                                />
                                                <div className='text-left'>
                                                    {errors.nombre_acompanante && <span className='text-red-500 font-semibold font-Roboto text-[13px]'>Nombre del acompañante es requerido</span>}
                                                </div>
                                            </div>
                                            </div>

                                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                            <label htmlFor="apellido_paterno_acompanante" className={`block text-left ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                                Apellido Paterno
                                            </label>
                                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                                <input
                                                id="apellido_paterno_acompanante"
                                                placeholder='Ingrese el apellido paterno del acompañante'
                                                name="apellido_paterno_acompanante"
                                                type="text"
                                                autoComplete="off"
                                                {...register('apellido_paterno_acompanante')}
                                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                                />
                                            </div>
                                            </div>

                                            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-2">
                                            <label htmlFor="apellido_materno_acompanante" className={`block text-left ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} font-bold leading-6 text-gray-900 sm:pt-1.5 font-Roboto dark:text-white`}>
                                                Apellido Materno
                                            </label>
                                            <div className="mt-2 sm:col-span-3 sm:mt-0">
                                                <input
                                                id="apellido_materno_acompanante"
                                                placeholder='Ingrese el apellido materno del acompañante'
                                                name="apellido_materno_acompanante"
                                                type="text"
                                                autoComplete="off"
                                                {...register('apellido_materno_acompanante')}
                                                className="block w-full rounded-md border-gray-300 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-color-secondary-azul focus:border-color-secondary-azul sm:text-sm font-Roboto"
                                                />
                                            </div>
                                            </div>

                                        </div>
                                        
                                    </>
                                )    
                            }

                        </div>
                        </div>

                        <div className='flex justify-between gap-3 mt-[11px]'>
                            <div>
                                <Limpiar />
                            </div>
                            <div className='flex gap-2'>
                                <div className='w-[160px] h-[48px]'>
                                    <button
                                        className={`bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'}`}
                                        type='button'
                                        onClick={() => navigate(-1)}
                                    >
                                        Volver
                                    </button>
                                </div>
                                <div className='w-[254px] h-[48px]'>
                                    <button
                                        type='submit'
                                        className={`bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'}`}
                                    >
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="flex flex-col gap-4 text-center lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF]">
                    <div className='text-left flex flex-col gap-1'>
                        <h2 className={`${textSize === 'small' ? 'text-[16px]' : textSize === 'medium' ? 'text-[18px]' : 'text-[20px]'} font-bold leading-7 text-gray-900 font-Roboto text-center`}>Datos Ingresados</h2>
                        <div className='py-3 border-t border-b border-gray-300 mt-2'>
                            <h3 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Evaluador</h3>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.rut}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.nombre}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_paterno}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_materno}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Profesión</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && getProfesionName(state.evaluador.profesion)}</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className='text-left flex flex-col gap-1'>
                        <div className='pb-4 border-b border-gray-300'>
                            <h2 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Usuario</h2>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.rut_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.nombre_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.apellido_paterno_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.apellido_materno_usuario}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Fecha de Nacimiento</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.fecha_nacimiento_usuario ? formatDate(watchedFields.fecha_nacimiento_usuario) : ''}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Edad</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.fecha_nacimiento_usuario ? calculateAge(watchedFields.fecha_nacimiento_usuario) : ''} </p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Sexo</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {showSexo(watchedFields.sexo_usuario)}</p>
                            </div>
                            <div className='flex'>
                                <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Informante</p>
                                <p className="text-sm text-gray-500 font-Roboto">: {showInformante(watchedFields.informante)}</p>
                            </div>
                            {showInformante(watchedFields.informante) === 'Acompañante' ? (
                                <>
                                    <div className='flex flex-col py-1'>
                                        <h3 className={`font-Roboto ${textSize === 'small' ? 'text-[14px]' : textSize === 'medium' ? 'text-[16px]' : 'text-[18px]'} py-1 font-semibold`}>Acompañante</h3>
                                        <div className='flex'>
                                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Rut</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.rut_acompanante}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Nombre</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.nombre_acompanante}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Paterno</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.apellido_paterno_acompanante}</p>
                                        </div>
                                        <div className='flex'>
                                            <p className={`${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} text-gray-500 w-1/2 font-Roboto`}>Apellido Materno</p>
                                            <p className="text-sm text-gray-500 font-Roboto">: {watchedFields.apellido_materno_acompanante}</p>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                        
                    </div>
                </div>


            </div>
        </>
    )
}

export default DatosUsuario