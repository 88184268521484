import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { useRegFormContext } from './providers/RegisterFromProvider';

import logo from '../src/assets/img/logo-minsal.svg'
import logo_100 from '../src/assets/img/logo100.svg'
import logo_100_white from '../src/assets/img/logo100-white.svg'
import ico_arrow from '../src/assets/img/ico_arrow.svg'
import contaste from '../src/assets/img/cl-contrast.svg'
import decreaseText from '../src/assets/img/cl-decrease-text.svg'
import increaseText from '../src/assets/img/cl-increase-text.svg'
import logo_compin from '../src/assets/img/logo-compin-superior.svg'

function App() {

    const navigate = useNavigate();
    const { resetState } = useRegFormContext();

    const [textSize, setTextSize] = useState('medium');

    const [tema, setTema] = useState(localStorage.getItem('tema') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"));

    useEffect(() => {
        localStorage.setItem('tema', tema);
        document.querySelector('html').classList.toggle('dark', tema === 'dark');
    }, [tema]);

    // Creamos una función llamada "cambiarTema"
    function cambiarTema() {
    // Usamos "setTema" para cambiar el tema a lo opuesto de lo que era antes
    setTema(temaAnterior => temaAnterior === 'light' ? 'dark' : 'light');
    // Y mostramos un mensaje en la consola para saber qué tema estamos usando
    console.log(tema);
    }


    const increaseTextSize = () => {
        console.log('increaseTextSize');
        if (textSize === 'small') {
            setTextSize('medium');
        } else if (textSize === 'medium') {
            setTextSize('large');
        }
    };
    
    const decreaseTextSize = () => {
        if (textSize === 'large') {
            setTextSize('medium');
        } else if (textSize === 'medium') {
            setTextSize('small');
        }
    };

    const handleNewEvaluation = () => {
        resetState();
        navigate('/');
    };
    

    return (
      <>
        <div className={`w-full h-[130px] bg-white dark:bg-color-azul-footer flex flex-col fixed z-10 ${textSize === 'small' ? 'text-sm' : textSize === 'medium' ? 'text-base' : 'text-lg'}`}>
            <div className='w-full h-[28px] bg-[#373737] flex items-center justify-center'>
                <div className='flex max-w-7xl mx-auto w-full justify-end items-center pr-5'>
                    <p className={`text-white ${textSize === 'small' ? 'text-[12px]' : textSize === 'medium' ? 'text-[14px]' : 'text-[16px]'} 'pr-3'`}>
                        Página oficial del Gobierno de Chile
                    </p>
                    <img src={ico_arrow} alt="Logo Gobierno de Chile" className='w-[12px] h-[6px]'/>
                </div>
            </div>
            <div className='flex max-w-7xl mx-auto w-full justify-between items-center'>
                <div className='flex gap-2'>
                    <Link
                        onClick={handleNewEvaluation}
                    >
                        <img src={logo} alt="Logo Gobierno de Chile" className='w-[100px] h-[95.25px]'/>
                    </Link>
                    <img src={tema === 'dark' ? logo_100_white : logo_100} alt="Logo Gobierno de Chile" className='w-[90px] h-[91.139px] relative top-0.5'/>
                </div>
                <div className='flex gap-2 pr-5'>
                    <button className='border-2 border-[#656565] w-[38px] h-[38.54px] flex justify-center items-center'
                        onClick={cambiarTema}
                    >
                        <img src={contaste} alt="Logo Gobierno de Chile" className='w-[18px] h-[18px]'/>
                    </button>
                    <button className='border-2 border-[#656565] w-[38px] h-[38.54px] flex justify-center items-center'
                        onClick={decreaseTextSize}
                    >
                        <img src={decreaseText} alt="Disminuir texto" className='w-[18px] h-[18px]'/>
                    </button>
                    <button className='border-2 border-[#656565] w-[38px] h-[38.54px] flex justify-center items-center'
                        onClick={increaseTextSize}
                    >
                        <img src={increaseText} alt="Aumentar texto" className='w-[18px] h-[18px]'/>
                    </button>
                </div>
            </div>
            <div className='border-b-2 border-[#AEAEAE] max-w-7xl mx-auto w-full pt-1'></div>
        </div>
        <div className="pt-[130px] pb-[100px] dark:bg-color-azul-contraste min-h-screen"> {/* h-screen */}
            <div className="flex items-center justify-center pt-[14px] lg:px-8" aria-label="Global">
                <div className='flex flex-col w-[730px]'>
                    <p className={`font-semibold ${textSize === 'small' ? 'text-[29px]' : textSize === 'medium' ? 'text-[31px]' : 'text-[33px]'} text-center font-Roboto dark:text-white`}>Instrumento de Valoración de Desempeño en Comunidad IVADEC-CIF</p>
                    <p className='font-semibold text-[25px] text-center font-Roboto dark:text-white'>Protocolo de Aplicación</p>
                </div>
            </div>

            <div className="relative isolate px-6 lg:px-8">
                
                <div className="flex flex-col items-center justify-center mx-auto max-w-7xl py-[45px] sm:py-[45px] lg:py-[45-px]">
                    <Outlet context={{ textSize, increaseTextSize, decreaseTextSize }} />
                </div>
            </div>
        </div>
        <footer className='w-full h-[100px] bg-color-azul-footer relative lg:fixed bottom-0'>
                <div className='flex flex-col max-w-7xl mx-auto w-full justify-center items-start pr-5 gap-2'>
                    <p className={`text-white ${textSize === 'small' ? 'text-[10px]' : textSize === 'medium' ? 'text-[12px]' : 'text-[14px]'} pr-3 font-Roboto pt-1`}>
                        Esta plataforma es administrada por:
                    </p>
                    <img src={logo_compin} alt="Logo COMPIN" className='w-[105.56px] h-[50px]'/>
                </div>
        </footer>
      </>
    );
}

export default App;
