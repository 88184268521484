import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRegFormContext } from '../providers/RegisterFromProvider';

import ScaleLoader from 'react-spinners/ScaleLoader';

import { downloadPdfDocument_B } from '../components/pdf/IvadecPDFB';

const Persona5anios9anios11meses29dias = () => {

    const [loading, setLoading] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        // Obtener el token CSRF del DOM
        const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        setCsrfToken(token);
      }, []);

    const { state, dispatch, resetState } = useRegFormContext()
    const handleDownloadPdf = () => {
        const pdfData = {
            ...state,
            grupo_etario: responseData.grupo_etario,
            indice_discapacidad: responseData.indice_discapacidad,
            indice_desempenio: responseData.indice_desempenio,
            grado_discapacidad: responseData.grado_discapacidad,
            calificacion: responseData.calificacion,
        };
        downloadPdfDocument_B(pdfData);
    };
    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState({});
    const { register, handleSubmit, watch, formState: { isValid, errors }} = useForm({
        mode: 'onChange',
        defaultValues: state.calculo // Inicializar con los valores del contexto
    });
    const watchedFields = watch(); // Observa todos los campos

    const handleNewEvaluation = () => {
        resetState();
        navigate('/');
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
    
        // Construye la fecha sin convertir a UTC
        const [year, month, day] = dateString.split('-');
        const date = new Date(year, month - 1, day);
    
        // Opciones para el formato de fecha
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('es-CL', options);
    }
    
    const calculateAge = (dateString) => {
        const birthday = new Date(dateString + 'T00:00:00'); // nacimiento en media noche para dar la edad cerrada.
        const today = new Date(new Date().toISOString().split('T')[0] + 'T00:00:00');
    
        today.setHours(0, 0, 0, 0);
        birthday.setHours(0, 0, 0, 0);
    
        let years = today.getFullYear() - birthday.getFullYear();
        let months = today.getMonth() - birthday.getMonth();
        let days = today.getDate() - birthday.getDate();
    
        if (days < 0) {
            // Ajustar el mes y calcular los días correctos del mes anterior
            months--;
            // Obtener el total de días del último mes (mes anterior al actual)
            let lastMonth = today.getMonth() - 1 < 0 ? 11 : today.getMonth() - 1;
            let lastYear = lastMonth === 11 ? today.getFullYear() - 1 : today.getFullYear();
            days += new Date(lastYear, lastMonth + 1, 0).getDate(); // Total de días en el mes anterior
        }
    
        if (months < 0) {
            years--;
            months += 12;
        }
    
        return `${years} ${years === 1 ? 'Año' : 'Años'}, ${months} ${months === 1 ? 'mes' : 'meses'}, y ${days} ${days === 1 ? 'día' : 'días'}`;
    }

    function formatOrigenesSecundarios(secundarios, principal) {
        if (!secundarios || secundarios.length === 0) {
            return 'Ninguno';
        }
        const nombresOrigenes = ["Visual", "Auditivo", "Físico", "Mental Intelectual", "Mental Psíquico"];
    
        // Convertimos 'principal' a un índice base 0 para comparar correctamente
        const principalIndex = parseInt(principal) - 1;
    
        const nombresActivos = secundarios.map((origen) => {
            // Convertimos cada origen a índice base 0
            const index = origen - 1;
            // Verificamos que no sea el principal
            return index !== principalIndex ? nombresOrigenes[index] : null;
        }).filter(nombre => nombre !== null); // Filtrar los nulos
    
        //(nombresActivos);
        return nombresActivos.join(', ') || 'Ninguno';
    }
    
    // {getProfesionName(watchedFields.profesion)}
    function getProfesionName(id) {
        const profesiones = {
            "1": "KINESIOLOGO(A)",
            "2": "TERAPEUTA OCUPACIONAL",
            "3": "FONOAUDIOLOGO (A)",
            "4": "PSICÓLOGO (A)",
            "5": "ENFERMERO (A)",
            "6": "MÉDICO",
            "7": "TRAB. SOCIAL COMPIN",
            "8": "TERAPEUTA EN ACTIVIDAD FÍSICA Y SALUD",
            "9": "MATRON (A)",
            "10": "NUTRICIONISTA",
            // ... otros mapeos
        };
    
        return profesiones[id] || "";
    }
    // mostrar el sexo e informante según id
    function showSexo(id) {
        const sexo = {
            1: 'Masculino',
            2: 'Femenino',
            3: 'Indeterminado / no binario',
        }
        return sexo[id] || '';
    }

    function showInformante(id) {
        const informante = {
            1: 'Usuario',
            2: 'Acompañante',
        }
        return informante[id] || '';
    }

    function showPrincipal(id) {
        const principal = {
            1: 'Visual',
            2: 'Auditivo',
            3: 'Físico',
            4: 'Mental Intelectual',
            5: 'Mental Psíquico',
        }
        return principal[id] || '';
    }

    const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
    const [responseData, setResponseData] = useState(null);
    //console.log(responseData)

    // Crear la referencia
    const resultRef = useRef(null);

    // Función para desplazarse al elemento referenciado
    const scrollToResult = () => {
        if (resultRef.current) {
            resultRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Efecto para realizar el desplazamiento cuando isSubmittedSuccessfully cambia a true
    useEffect(() => {
        if (isSubmittedSuccessfully) {
            scrollToResult();
        }
    }, [isSubmittedSuccessfully]);

    useEffect(() => {
        const updatedErrorMessages = { ...errorMessages };
        for (let key in watchedFields) {
            if (key.endsWith('_A') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            } else if (key.endsWith('_B') && watchedFields[key] !== '-' && errorMessages[key]) {
                delete updatedErrorMessages[key];
            }
        }
        setErrorMessages(updatedErrorMessages);
    }, [
        watchedFields.mirar_A, watchedFields.mirar_B, watchedFields.mirar_C,
        watchedFields.escuchar_A, watchedFields.escuchar_B, watchedFields.escuchar_C,
        watchedFields.aprender_leer_A, watchedFields.aprender_leer_B, watchedFields.aprender_leer_C,
        watchedFields.centrar_atencion_A, watchedFields.centrar_atencion_B, watchedFields.centrar_atencion_C,
        watchedFields.resolver_problemas_simples_A, watchedFields.resolver_problemas_simples_B, watchedFields.resolver_problemas_simples_C,
        watchedFields.completar_rutina_diaria_A, watchedFields.completar_rutina_diaria_B, watchedFields.completar_rutina_diaria_C,
        watchedFields.conversar_una_sola_persona_A, watchedFields.conversar_una_sola_persona_B, watchedFields.conversar_una_sola_persona_C,
        watchedFields.utilizacion_dispositivos_telecomunicacion_A, watchedFields.utilizacion_dispositivos_telecomunicacion_B, watchedFields.utilizacion_dispositivos_telecomunicacion_C,
        watchedFields.ponerse_de_pie_A, watchedFields.ponerse_de_pie_B, watchedFields.ponerse_de_pie_C,
        watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_A, watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_B, watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_C,
        watchedFields.utilizacion_de_transporte_publico_con_motor_A, watchedFields.utilizacion_de_transporte_publico_con_motor_B, watchedFields.utilizacion_de_transporte_publico_con_motor_C,
        watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_A, watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_B, watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_C,
        watchedFields.ponerse_la_ropa_A, watchedFields.ponerse_la_ropa_B, watchedFields.ponerse_la_ropa_C,
        watchedFields.comer_A, watchedFields.comer_B, watchedFields.comer_C,
        watchedFields.beber_A, watchedFields.beber_B, watchedFields.beber_C,
        watchedFields.buscar_consejo_cuidadores_profesionales_A, watchedFields.buscar_consejo_cuidadores_profesionales_B, watchedFields.buscar_consejo_cuidadores_profesionales_C,
        watchedFields.cuidado_propia_seguridad_A, watchedFields.cuidado_propia_seguridad_B, watchedFields.cuidado_propia_seguridad_C,
        watchedFields.comprar_A, watchedFields.comprar_B, watchedFields.comprar_C,
        watchedFields.ayudar_preparar_comida_A, watchedFields.ayudar_preparar_comida_B, watchedFields.ayudar_preparar_comida_C,
        watchedFields.ayudar_quehaceres_casa_A, watchedFields.ayudar_quehaceres_casa_B, watchedFields.ayudar_quehaceres_casa_C,
        watchedFields.interacciones_interpersonales_basicas_A, watchedFields.interacciones_interpersonales_basicas_B, watchedFields.interacciones_interpersonales_basicas_C,
        watchedFields.establecer_relaciones_A, watchedFields.establecer_relaciones_B, watchedFields.establecer_relaciones_C,
        watchedFields.relaciones_informales_con_amigos_A, watchedFields.relaciones_informales_con_amigos_B, watchedFields.relaciones_informales_con_amigos_C,
        watchedFields.relaciones_familiares_A, watchedFields.relaciones_familiares_B, watchedFields.relaciones_familiares_C,
        watchedFields.educacion_escolar_A, watchedFields.educacion_escolar_B, watchedFields.educacion_escolar_C,
        watchedFields.socializacion_A, watchedFields.socializacion_B, watchedFields.socializacion_C || 0
    ]);
    
    const onSubmit = async (values) => {
        const updatedValues = { ...values };

        // Iterar sobre las claves para actualizar los valores que terminen en '_C'
        Object.keys(updatedValues).forEach(key => {
            if (key.endsWith('_C')) {
                updatedValues[key] = updatedValues[key] === '-' ? '0' : updatedValues[key];
            }
        });

        let newErrorMessages = {};

        for (let key in updatedValues) {
            if (key.endsWith('_A') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo A es obligatorio';
            } else if (key.endsWith('_B') && updatedValues[key] === '-') {
                newErrorMessages[key] = 'Campo B es obligatorio';
            }
        }

        if (Object.keys(newErrorMessages).length > 0) {
            setErrorMessages(newErrorMessages);
            return;
        }

        // Asegúrate de incluir todos los datos que necesitas enviar
        if (isValid) {
            dispatch({ type: 'SET_DATA_CALCULO', data: updatedValues });
            // Otras acciones que quieras realizar después de la actualización del estado
        }
        const dataToSend = {
            evaluador: {
                rut: state.evaluador.rut,
                nombre: state.evaluador.nombre,
                apellido_paterno: state.evaluador.apellido_paterno,
                apellido_materno: state.evaluador.apellido_materno,
                profesion: state.evaluador.profesion
            },
            usuario: {
                rut_usuario: state.personal.rut_usuario,
                nombre_usuario: state.personal.nombre_usuario,
                apellido_paterno_usuario: state.personal.apellido_paterno_usuario,
                apellido_materno_usuario: state.personal.apellido_materno_usuario,
                fecha_nacimiento_usuario: state.personal.fecha_nacimiento_usuario,
                sexo_usuario: state.personal.sexo_usuario,
                informante: state.personal.informante,
                rut_acompanante: state.personal?.rut_acompanante,
                nombre_acompanante: state.personal?.nombre_acompanante,
                apellido_paterno_acompanante: state.personal?.apellido_paterno_acompanante,
                apellido_materno_acompanante: state.personal?.apellido_materno_acompanante
            },
            origen: {
                principal: state.origen.principal,
                secundario: state.origen.secundario
            },
            gradoDificultad: {
                mirar_A: values.mirar_A,
                mirar_B: values.mirar_B,
                mirar_C: values.mirar_C || 0,

                escuchar_A: values.escuchar_A,
                escuchar_B: values.escuchar_B,
                escuchar_C: values.escuchar_C || 0,

                aprender_leer_A: values.aprender_leer_A,
                aprender_leer_B: values.aprender_leer_B,
                aprender_leer_C: values.aprender_leer_C || 0,

                centrar_atencion_A: values.centrar_atencion_A,
                centrar_atencion_B: values.centrar_atencion_B,
                centrar_atencion_C: values.centrar_atencion_C || 0,

                resolver_problemas_simples_A: values.resolver_problemas_simples_A,
                resolver_problemas_simples_B: values.resolver_problemas_simples_B,
                resolver_problemas_simples_C: values.resolver_problemas_simples_C || 0,

                completar_rutina_diaria_A: values.completar_rutina_diaria_A,
                completar_rutina_diaria_B: values.completar_rutina_diaria_B,
                completar_rutina_diaria_C: values.completar_rutina_diaria_C || 0,

                conversar_una_sola_persona_A: values.conversar_una_sola_persona_A,
                conversar_una_sola_persona_B: values.conversar_una_sola_persona_B,
                conversar_una_sola_persona_C: values.conversar_una_sola_persona_C || 0,

                utilizacion_dispositivos_telecomunicacion_A: values.utilizacion_dispositivos_telecomunicacion_A,
                utilizacion_dispositivos_telecomunicacion_B: values.utilizacion_dispositivos_telecomunicacion_B,
                utilizacion_dispositivos_telecomunicacion_C: values.utilizacion_dispositivos_telecomunicacion_C || 0,

                ponerse_de_pie_A: values.ponerse_de_pie_A,
                ponerse_de_pie_B: values.ponerse_de_pie_B,
                ponerse_de_pie_C: values.ponerse_de_pie_C || 0,

                desplazarse_fuera_del_hogar_y_otros_edificios_A: values.desplazarse_fuera_del_hogar_y_otros_edificios_A,
                desplazarse_fuera_del_hogar_y_otros_edificios_B: values.desplazarse_fuera_del_hogar_y_otros_edificios_B,
                desplazarse_fuera_del_hogar_y_otros_edificios_C: values.desplazarse_fuera_del_hogar_y_otros_edificios_C || 0,

                utilizacion_de_transporte_publico_con_motor_A: values.utilizacion_de_transporte_publico_con_motor_A,
                utilizacion_de_transporte_publico_con_motor_B: values.utilizacion_de_transporte_publico_con_motor_B,
                utilizacion_de_transporte_publico_con_motor_C: values.utilizacion_de_transporte_publico_con_motor_C || 0,

                higiene_personal_relacionada_con_los_procesos_de_excrecion_A: values.higiene_personal_relacionada_con_los_procesos_de_excrecion_A,
                higiene_personal_relacionada_con_los_procesos_de_excrecion_B: values.higiene_personal_relacionada_con_los_procesos_de_excrecion_B,
                higiene_personal_relacionada_con_los_procesos_de_excrecion_C: values.higiene_personal_relacionada_con_los_procesos_de_excrecion_C || 0,

                ponerse_la_ropa_A: values.ponerse_la_ropa_A,
                ponerse_la_ropa_B: values.ponerse_la_ropa_B,
                ponerse_la_ropa_C: values.ponerse_la_ropa_C || 0,

                comer_A: values.comer_A,
                comer_B: values.comer_B,
                comer_C: values.comer_C || 0,

                beber_A: values.beber_A,
                beber_B: values.beber_B,
                beber_C: values.beber_C || 0,

                buscar_consejo_cuidadores_profesionales_A: values.buscar_consejo_cuidadores_profesionales_A,
                buscar_consejo_cuidadores_profesionales_B: values.buscar_consejo_cuidadores_profesionales_B,
                buscar_consejo_cuidadores_profesionales_C: values.buscar_consejo_cuidadores_profesionales_C || 0,

                cuidado_propia_seguridad_A: values.cuidado_propia_seguridad_A,
                cuidado_propia_seguridad_B: values.cuidado_propia_seguridad_B,
                cuidado_propia_seguridad_C: values.cuidado_propia_seguridad_C || 0,

                comprar_A: values.comprar_A,
                comprar_B: values.comprar_B,
                comprar_C: values.comprar_C || 0,

                ayudar_preparar_comida_A: values.ayudar_preparar_comida_A,
                ayudar_preparar_comida_B: values.ayudar_preparar_comida_B,
                ayudar_preparar_comida_C: values.ayudar_preparar_comida_C || 0,

                ayudar_quehaceres_casa_A: values.ayudar_quehaceres_casa_A,
                ayudar_quehaceres_casa_B: values.ayudar_quehaceres_casa_B,
                ayudar_quehaceres_casa_C: values.ayudar_quehaceres_casa_C || 0,

                interacciones_interpersonales_basicas_A: values.interacciones_interpersonales_basicas_A,
                interacciones_interpersonales_basicas_B: values.interacciones_interpersonales_basicas_B,
                interacciones_interpersonales_basicas_C: values.interacciones_interpersonales_basicas_C || 0,

                establecer_relaciones_A: values.establecer_relaciones_A,
                establecer_relaciones_B: values.establecer_relaciones_B,
                establecer_relaciones_C: values.establecer_relaciones_C || 0,

                relaciones_informales_con_amigos_A: values.relaciones_informales_con_amigos_A,
                relaciones_informales_con_amigos_B: values.relaciones_informales_con_amigos_B,
                relaciones_informales_con_amigos_C: values.relaciones_informales_con_amigos_C || 0,

                relaciones_familiares_A: values.relaciones_familiares_A,
                relaciones_familiares_B: values.relaciones_familiares_B,
                relaciones_familiares_C: values.relaciones_familiares_C || 0,

                educacion_escolar_A: values.educacion_escolar_A,
                educacion_escolar_B: values.educacion_escolar_B,
                educacion_escolar_C: values.educacion_escolar_C || 0,

                socializacion_A: values.socializacion_A,
                socializacion_B: values.socializacion_B,
                socializacion_C: values.socializacion_C || 0,
                
            }
        };
        setLoading(true);
        //console.log(dataToSend);
    
        try {
            //const response = await fetch(`${process.env.REACT_APP_API_URL}api/ivadec_B_add/`, {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/ivadec_B_add/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                body: JSON.stringify(dataToSend)
            });
    
            if (!response.ok) {
                // Si la respuesta no es 'ok', intenta obtener más detalles del error
                const errorData = await response.json(); // asumiendo que el servidor devuelve un JSON con detalles del error
                const errorMsg = `Error en el envío de datos: ${response.status} - ${response.statusText}. Detalles: ${JSON.stringify(errorData)}`;
                throw new Error(errorMsg);
            }
        
    
            // Procesa la respuesta del servidor aquí
            const data = await response.json();  // Asegúrate de que el backend envía una respuesta JSON
            setResponseData(data);  // Almacena la respuesta en el estado
            setIsSubmittedSuccessfully(true);
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleGoBack = () => {
        handleSubmit((values) => {
            dispatch({ type: 'SET_DATA_CALCULO', data: values });  // Asumiendo que este es el tipo de acción para guardar los datos de cálculo
            navigate(-1);  // Navegar hacia atrás después de actualizar el estado
        })();  // Llama a la función handleSubmit programáticamente
    };

    return (
        <>
            {/* <div className="grid min-h-full grid-cols-1 grid-rows-2 lg:grid-cols-2 lg:grid-rows-1"> */}
            <div className='flex flex-col lg:flex-row w-full border rounded-[5px]'>
                <div className="flex flex-col gap-4 justify-center item-center text-center lg:w-8/12 px-6 py-[15px] pb-4">
                    <div className='p-2 rounded-[5px] bg-color-secondary-azul'>
                        <p className='font-bold text-lg font-Roboto dark:text-white text-white'>PERSONA DE 5 AÑOS A 9 AÑOS 11 MESES Y 29 DÍAS</p>
                    </div>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    >
                    <div className="overflow-x-auto relative">
                        <table className="w-full text-sm divide-y divide-gray-200">
                        {/* Encabezado de la tabla */}
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">Código</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">Actividades</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">A</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">B</th>
                                <th className="px-3 py-2 font-bold text-left text-gray-600 font-Roboto dark:text-color-azul-contraste">C</th>
                            </tr>
                        </thead>
                        {/* Cuerpo de la tabla */}
                        <tbody className="divide-y divide-gray-200">
                            {/* Itera por cada fila de datos aquí */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d110</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Mirar</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("mirar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.mirar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("mirar_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.mirar_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.mirar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.mirar_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("mirar_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d115</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Escuchar</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("escuchar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.escuchar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("escuchar_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.escuchar_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.escuchar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.escuchar_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("escuchar_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d140</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Aprender a leer</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("aprender_leer_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.aprender_leer_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.aprender_leer_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("aprender_leer_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.aprender_leer_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.aprender_leer_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.aprender_leer_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("aprender_leer_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d160</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Centrar la atención</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("centrar_atencion_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.centrar_atencion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.centrar_atencion_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("centrar_atencion_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.centrar_atencion_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.centrar_atencion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.centrar_atencion_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("centrar_atencion_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            {/* ################# SEGUNDA SECCIÓN DEL CUESTIONARIO #################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d1750</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Resolver problemas simples</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("resolver_problemas_simples_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.resolver_problemas_simples_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.resolver_problemas_simples_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("resolver_problemas_simples_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.resolver_problemas_simples_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.resolver_problemas_simples_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.resolver_problemas_simples_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("resolver_problemas_simples_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d2302</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Completar la rutina diaria</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("completar_rutina_diaria_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.completar_rutina_diaria_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.completar_rutina_diaria_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("completar_rutina_diaria_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.completar_rutina_diaria_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.completar_rutina_diaria_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.completar_rutina_diaria_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("completar_rutina_diaria_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d3503</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Conversar con una sola persona</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("conversar_una_sola_persona_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.conversar_una_sola_persona_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.conversar_una_sola_persona_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("conversar_una_sola_persona_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.conversar_una_sola_persona_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.conversar_una_sola_persona_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.conversar_una_sola_persona_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("conversar_una_sola_persona_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d3600</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Utilización de dispositivos de telecomunicación</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("utilizacion_dispositivos_telecomunicacion_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.utilizacion_dispositivos_telecomunicacion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_dispositivos_telecomunicacion_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("utilizacion_dispositivos_telecomunicacion_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.utilizacion_dispositivos_telecomunicacion_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.utilizacion_dispositivos_telecomunicacion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_dispositivos_telecomunicacion_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("utilizacion_dispositivos_telecomunicacion_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            {/* ######################## TERCERA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4104</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Ponerse de pie</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ponerse_de_pie_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.ponerse_de_pie_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_de_pie_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ponerse_de_pie_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.ponerse_de_pie_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.ponerse_de_pie_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_de_pie_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_de_pie_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4602</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Desplazarse fuera del hogar y de otros edificios</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("desplazarse_fuera_del_hogar_y_otros_edificios_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("desplazarse_fuera_del_hogar_y_otros_edificios_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.desplazarse_fuera_del_hogar_y_otros_edificios_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("desplazarse_fuera_del_hogar_y_otros_edificios_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d4702</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Utilización de transporte público con motor</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("utilizacion_de_transporte_publico_con_motor_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.utilizacion_de_transporte_publico_con_motor_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_de_transporte_publico_con_motor_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("utilizacion_de_transporte_publico_con_motor_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.utilizacion_de_transporte_publico_con_motor_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.utilizacion_de_transporte_publico_con_motor_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.utilizacion_de_transporte_publico_con_motor_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("utilizacion_de_transporte_publico_con_motor_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d530</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Higiene personal relacionada con los procesos de excreción</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("higiene_personal_relacionada_con_los_procesos_de_excrecion_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("higiene_personal_relacionada_con_los_procesos_de_excrecion_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.higiene_personal_relacionada_con_los_procesos_de_excrecion_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("higiene_personal_relacionada_con_los_procesos_de_excrecion_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            {/* ######################## CUARTA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d5400</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Ponerse la ropa</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ponerse_la_ropa_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.ponerse_la_ropa_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_la_ropa_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ponerse_la_ropa_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.ponerse_la_ropa_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.ponerse_la_ropa_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ponerse_la_ropa_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ponerse_la_ropa_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d550</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comer</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("comer_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.comer_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comer_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("comer_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.comer_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.comer_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comer_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comer_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d560</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Beber</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("beber_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.beber_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.beber_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("beber_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.beber_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.beber_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.beber_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("beber_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d57021</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Buscar consejo o ayuda de cuidadores o profesionales</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("buscar_consejo_cuidadores_profesionales_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.buscar_consejo_cuidadores_profesionales_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.buscar_consejo_cuidadores_profesionales_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("buscar_consejo_cuidadores_profesionales_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.buscar_consejo_cuidadores_profesionales_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.buscar_consejo_cuidadores_profesionales_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.buscar_consejo_cuidadores_profesionales_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("buscar_consejo_cuidadores_profesionales_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            
                            {/* ######################## QUINTA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d571</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Cuidado de la propia salud</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("cuidado_propia_seguridad_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.cuidado_propia_seguridad_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.cuidado_propia_seguridad_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("cuidado_propia_seguridad_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.cuidado_propia_seguridad_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.cuidado_propia_seguridad_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.cuidado_propia_seguridad_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("cuidado_propia_seguridad_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d6200</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Comprar</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("comprar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.comprar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comprar_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("comprar_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.comprar_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.comprar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.comprar_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("comprar_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d6302</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Ayudar a preparar comidas</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ayudar_preparar_comida_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.ayudar_preparar_comida_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ayudar_preparar_comida_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ayudar_preparar_comida_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.ayudar_preparar_comida_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.ayudar_preparar_comida_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ayudar_preparar_comida_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ayudar_preparar_comida_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d6406</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Ayudar a hacer los quehaceres de la casa</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ayudar_quehaceres_casa_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.ayudar_quehaceres_casa_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ayudar_quehaceres_casa_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("ayudar_quehaceres_casa_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.ayudar_quehaceres_casa_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.ayudar_quehaceres_casa_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.ayudar_quehaceres_casa_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("ayudar_quehaceres_casa_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            {/* ######################## SEXTA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d710</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Interacciones interpersonales básicas</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("interacciones_interpersonales_basicas_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.interacciones_interpersonales_basicas_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.interacciones_interpersonales_basicas_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("interacciones_interpersonales_basicas_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.interacciones_interpersonales_basicas_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.interacciones_interpersonales_basicas_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.interacciones_interpersonales_basicas_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("interacciones_interpersonales_basicas_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d7200</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Establecer relaciones</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("establecer_relaciones_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.establecer_relaciones_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.establecer_relaciones_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("establecer_relaciones_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.establecer_relaciones_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.establecer_relaciones_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.establecer_relaciones_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("establecer_relaciones_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d7500</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Relaciones informales con amigos</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("relaciones_informales_con_amigos_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.relaciones_informales_con_amigos_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_informales_con_amigos_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("relaciones_informales_con_amigos_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.relaciones_informales_con_amigos_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.relaciones_informales_con_amigos_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_informales_con_amigos_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_informales_con_amigos_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d760</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Relaciones familiares</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("relaciones_familiares_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.relaciones_familiares_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_familiares_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("relaciones_familiares_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.relaciones_familiares_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.relaciones_familiares_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.relaciones_familiares_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("relaciones_familiares_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            {/* ######################## SÉPTIMA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d820</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Educación escolar</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("educacion_escolar_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.educacion_escolar_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.educacion_escolar_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("educacion_escolar_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.educacion_escolar_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.educacion_escolar_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.educacion_escolar_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("educacion_escolar_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                            {/* ######################## OCTAVA SECCIÓN ############################### */}
                            <tr>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">d9205</td>
                                <td className="px-3 py-2 text-gray-700 font-Roboto text-left dark:text-white">Socialización</td>
                                {/* Columna A */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("socializacion_A", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className='absolute left-0'>
                                        {errorMessages.socializacion_A && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.socializacion_A}</span>}
                                    </div>
                                </td>
                                {/* Columna B */}
                                <td className="px-3 py-1">
                                    <select
                                        className="form-select block w-full mt-1 rounded-md border-color-secondary-azul shadow-sm focus:border-color-secondary-azul focus:ring focus:ring-color-secondary-azul focus:ring-opacity-50 mr-1"
                                        {...register("socializacion_B", { required: true })}>
                                        {['-', 0, 1, 2, 3, 4].map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                    <div className={`absolute ${errorMessages.socializacion_A ? 'left-44' : 'left-0' }`}>
                                        {errorMessages.socializacion_B && <span className="text-red-500 font-semibold font-Roboto text-[13px] relative bottom-3">{errorMessages.socializacion_B}</span>}
                                    </div>
                                </td>
                                {/* Columna C */}
                                <td className="px-3 py-2">
                                    <select 
                                        {...register("socializacion_C")}  
                                        className="form-select block w-full mt-1 rounded-md border-secondary-azul shadow-sm focus:border-secondary-azul focus:ring focus:ring-secondary-azul focus:ring-opacity-50 font-Roboto mr-1">
                                    {['-', 0, 1, 2, 3, 4].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    {/* Botones al final */}
                    <div className='flex justify-end gap-3 mt-[11px]'>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleGoBack}
                            >
                                Volver
                            </button>
                        </div>
                        <div className='w-[254px] h-[48px]'>
                            <button
                                type='submit'
                                //disabled={isSubmittedSuccessfully}
                                className='bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                            >
                                {loading ? <ScaleLoader color='#ffffff' loading={true} height={30} width={4} radius={2} margin={2} /> : 'Calcular'}
                            </button>
                        </div>
                        <div className='w-[160px] h-[48px]'>
                            <button
                                className='bg-white hover:bg-color-secondary-azul-hover border border-[#0F69C4] text-[#0F69C4] hover:text-white font-bold w-full h-full rounded-[5px] font-Roboto underline text-16px'
                                type='button'
                                onClick={handleNewEvaluation}
                            >
                                Nueva Evaluación
                            </button>
                        </div>
                    </div>
                </form>

                </div>

                {/* AQUI VIENEN LOS DATOS ANTERIORES */}
                {/* DATOS DEL EVALUADOR */}
                <div className="overflow-auto lg:w-4/12 px-6 py-[16px] pb-4 bg-[#DBEDFF] rounded-e-[5px]">
                    <div className='text-left flex flex-col gap-1'>
                        <h2 className="text-[18px] font-bold leading-7 text-gray-900 font-Roboto text-center">Datos Ingresados</h2>

                            <div className='py-3 border-t border-b border-gray-300 mt-2'>
                                <h3 className='font-Roboto text-sm py-1 font-semibold'>Evaluador</h3>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Rut</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.rut}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Nombre</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.nombre}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Paterno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_paterno}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Materno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && state.evaluador.apellido_materno}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Profesión</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.evaluador && getProfesionName(state.evaluador?.profesion)}</p>
                                </div>
                            </div>
                        </div>
                        {/* DATOS DEL USUARIO */}
                        <div className='text-left flex flex-col gap-1'>
                            <div className='pb-4 border-b border-gray-300'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Usuario</h2>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Rut</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Nombre</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Paterno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Materno</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_usuario}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Fecha de Nacimiento</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? formatDate(state.personal.fecha_nacimiento_usuario
                                        ) : ''}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Edad</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.fecha_nacimiento_usuario ? calculateAge(state.personal.fecha_nacimiento_usuario) : ''} </p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Sexo</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showSexo(state.personal?.sexo_usuario)}</p>
                                </div>
                                <div className='flex'>
                                    <p className="text-sm text-gray-500 font-Roboto w-1/2">Informante</p>
                                    <p className="text-sm text-gray-500 font-Roboto">: {state.personal && showInformante(state.personal?.informante)}</p>
                                </div>
                                {state.personal && showInformante(state.personal?.informante) === 'Acompañante' ? (
                                    <>
                                        <div className='flex flex-col mt-1'>
                                            <h3 className='font-Roboto text-sm py-1 font-semibold'>Acompañante</h3>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Rut</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.rut_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Nombre</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.nombre_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Paterno</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_paterno_acompanante}</p>
                                            </div>
                                            <div className='flex'>
                                                <p className="text-sm text-gray-500 font-Roboto w-1/2">Apellido Materno</p>
                                                <p className="text-sm text-gray-500 font-Roboto">: {state.personal && state.personal.apellido_materno_acompanante}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>

                            <div className='text-left flex flex-col gap-1'>
                                <div className='pb-4 border-b border-gray-300'>
                                    <h2 className="font-Roboto text-sm py-1 font-semibold">Origen</h2>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Principal</p>
                                        <p className="text-sm text-gray-500 font-Roboto">: {state.origen && showPrincipal(state.origen.principal)}</p>
                                    </div>
                                    <div className='flex'>
                                        <p className="text-sm text-gray-500 w-1/2 font-Roboto">Secundario(s)</p>
                                        <div className='w-1/2'>
                                            <p className="text-sm text-gray-500 font-Roboto">: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            
                        </div>
                        {/* Calculadora */}
                        <div className='text-left border-b flex flex-col gap-1'>
                            <div className='pb-4'>
                                <h2 className="font-Roboto text-sm py-1 font-semibold">Cálculo</h2>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Mirar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.mirar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.mirar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.mirar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Escuchar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.escuchar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.escuchar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.escuchar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Aprender a leer</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.aprender_leer_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.aprender_leer_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.aprender_leer_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Centrar la atención</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.centrar_atencion_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.centrar_atencion_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.centrar_atencion_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Resolver problemas simples</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.resolver_problemas_simples_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.resolver_problemas_simples_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.resolver_problemas_simples_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Completar la rutina diaria</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.completar_rutina_diaria_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.completar_rutina_diaria_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.completar_rutina_diaria_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Conversar con una sola persona</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.conversar_una_sola_persona_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.conversar_una_sola_persona_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.conversar_una_sola_persona_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Utilización de dispositivos de telecomunicación</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.utilizacion_dispositivos_telecomunicacion_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.utilizacion_dispositivos_telecomunicacion_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.utilizacion_dispositivos_telecomunicacion_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Ponerse de pie</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.ponerse_de_pie_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.ponerse_de_pie_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.ponerse_de_pie_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Desplazarse fuera del hogar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.desplazarse_fuera_del_hogar_y_otros_edificios_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Utilización de transporte público </p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.utilizacion_de_transporte_publico_con_motor_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.utilizacion_de_transporte_publico_con_motor_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.utilizacion_de_transporte_publico_con_motor_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Higiene personal relacionada</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.higiene_personal_relacionada_con_los_procesos_de_excrecion_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Ponerse la ropa</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.ponerse_la_ropa_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.ponerse_la_ropa_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.ponerse_la_ropa_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Comer</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.comer_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.comer_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.comer_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Beber</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.beber_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.beber_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.beber_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Buscar consejo o ayuda</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.buscar_consejo_cuidadores_profesionales_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.buscar_consejo_cuidadores_profesionales_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.buscar_consejo_cuidadores_profesionales_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Cuidado de la propia salud</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.cuidado_propia_seguridad_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.cuidado_propia_seguridad_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.cuidado_propia_seguridad_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Comprar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.comprar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.comprar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.comprar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Ayudar a preparar comidas</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.ayudar_preparar_comida_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.ayudar_preparar_comida_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.ayudar_preparar_comida_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Ayudar a hacer los quehaceres de la casa</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.ayudar_quehaceres_casa_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.ayudar_quehaceres_casa_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.ayudar_quehaceres_casa_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Interacciones interpersonales</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.interacciones_interpersonales_basicas_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.interacciones_interpersonales_basicas_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.interacciones_interpersonales_basicas_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Establecer relaciones</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.establecer_relaciones_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.establecer_relaciones_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.establecer_relaciones_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Relaciones informales con amigos</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.relaciones_informales_con_amigos_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.relaciones_informales_con_amigos_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.relaciones_informales_con_amigos_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Relaciones familiares</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.relaciones_familiares_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.relaciones_familiares_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.relaciones_familiares_C}</p>
                                </div>
                            </div>

                            <div className='pb-4'>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Educación escolar</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.educacion_escolar_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.educacion_escolar_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.educacion_escolar_C}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 font-Roboto w-9/12'>Socialización</p> 
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>A: {watchedFields.socializacion_A}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>B: {watchedFields.socializacion_B}</p>
                                    <p className='text-sm text-gray-500 font-Roboto w-2/12'>C: {watchedFields.socializacion_C}</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            {
                isSubmittedSuccessfully ? (
                    <>
                        <div className='border w-full mt-12 rounded-[5px] bg-white shadow hover:shadow-2xl dark:bg-color-azul-contraste' ref={resultRef}>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>Datos guardados con exito</h2>
                            <h2 className='text-center p-2 text-lg font-semibold font-Roboto dark:text-white'>RESULTADOS:</h2>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Origen principal de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {state.origen && showPrincipal(state.origen.principal)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Otros orígenes</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {formatOrigenesSecundarios(state.origen.secundario)}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grupo etario</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grupo_etario : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_discapacidad : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Índice de desempeño</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.indice_desempenio : 'Cargando...'}</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Porcentaje de discapacidad</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.grado_discapacidad : 'Cargando...'} %</p>
                            </div>
                            <div className='flex gap-6 justify-center items-center'>
                                <p className='w-6/12 text-right font-Roboto dark:text-white'>Grado de discapacidad:</p>
                                <p className='w-6/12 text-left font-semibold font-Roboto dark:text-white'>: {responseData ? responseData.calificacion : 'Cargando...'}</p>
                            </div>

                            <div className='flex justify-center items-center my-8'>
                                <button onClick={() => handleDownloadPdf(state)} className="px-16 py-2 bg-color-secondary-azul hover:bg-color-secondary-azul-hover text-white font-Roboto rounded-[5px]">Guardar PDF</button>
                            </div>

                        </div>
                    </>
                ) : null
            }
        </>
    )
}

export default Persona5anios9anios11meses29dias